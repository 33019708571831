<template>
    <div class="col-12">
        <v-row>
            <v-col cols="4">
                <v-data-table
                    :headers="importDutiesHeaders"
                    :items="importDuties"
                    :items-per-page="-1"
                    :sort-by="['ImportDuty.office_id','ImportDuty.product']"
                    :height="tableHeight"
                    :fixed-header="true"
                    class="appic-table-light specification-table"
                    dense
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top="{ items }">
                        <v-overlay
                            :value="loading.importDuties"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <div class="d-flex flex-row pb-3">
                            <span>{{ $t('message.importDuty') }}</span>
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openAddVariableDialog('import-duty')">
                                        <v-icon small dark v-on="on">ti ti-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.new') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.ImportDuty.productgroup_id="{ item }">
                        {{ item.ImportDuty.product }}
                    </template>
                    <template v-slot:item.ImportDuty.office_id="{ item }">
                        {{ item.ImportDuty.warehouse }}
                    </template>
                    <template v-slot:item.ImportDuty.id="{ item }">
                        <div class="text-center">
                            <v-menu>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.moreActions') }}</span>
                                    </v-tooltip>
                                </template>
                                <v-list dense>
                                    <v-list-item class="font-sm" @click="openEditVariableDialog('import-duty', item.ImportDuty.id, item.ImportDuty.product)">
                                        <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateImportDuty') }}
                                    </v-list-item>
                                    <v-list-item class="font-sm" @click="deleteVariable('import-duty', item.ImportDuty.id, item.ImportDuty.product)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteImportDuty') }}</v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="4">
                <v-data-table
                    :headers="forwarderCostsHeaders"
                    :items="forwarderCosts"
                    :items-per-page="-1"
                    :height="tableHeight"
                    :fixed-header="true"
                    :sort-by="['ForwarderCost.office_id','ForwarderCost.product']"
                    class="appic-table-light specification-table"
                    dense
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top="{ items }">
                        <v-overlay
                            :value="loading.forwarderCosts"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <div class="d-flex flex-row pb-3">
                            <span>{{ $t('message.forwarderCost') }}</span>
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openAddVariableDialog('forwarder-cost')">
                                        <v-icon small dark v-on="on">ti ti-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.new') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.ForwarderCost.productgroup_id="{ item }">
                        {{ item.ForwarderCost.product }}
                    </template>
                    <template v-slot:item.ForwarderCost.office_id="{ item }">
                        {{ item.ForwarderCost.warehouse }}
                    </template>
                    <template v-slot:item.ForwarderCost.id="{ item }">
                        <div class="text-center">
                            <v-menu>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.moreActions') }}</span>
                                    </v-tooltip>
                                </template>
                                <v-list dense>
                                    <v-list-item class="font-sm" @click="openEditVariableDialog('forwarder-cost', item.ForwarderCost.id, item.ForwarderCost.product)">
                                        <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateForwarderCost') }}
                                    </v-list-item>
                                    <v-list-item class="font-sm" @click="deleteVariable('forwarder-cost', item.ForwarderCost.id, item.ForwarderCost.product)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteForwarderCost') }}</v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-data-table
                    :items="storageCosts"
                    :headers="storageCostsHeaders"
                    :items-per-page="-1"
                    :height="tableHeight"
                    :fixed-header="true"
                    :sort-by="'StorageCost.office_id'"
                    class="appic-table-light specification-table"
                    dense
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top="{ items }">
                        <v-overlay
                            :value="loading.storageCosts"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <div class="d-flex flex-row pb-3">
                            <span>{{ $t('message.storageCost') }}</span>
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openAddVariableDialog('storage-cost')">
                                        <v-icon small dark v-on="on">ti ti-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.new') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.StorageCost.office_id="{ item }">
                        {{ item.StorageCost.warehouse }}
                    </template>
                    <template v-slot:item.StorageCost.id="{ item }">
                        <div class="text-center">
                            <v-menu>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.moreActions') }}</span>
                                    </v-tooltip>
                                </template>
                                <v-list dense>
                                    <v-list-item class="font-sm" @click="openEditVariableDialog('storage-cost', item.StorageCost.id, item.StorageCost.office)">
                                        <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateStorageCost') }}
                                    </v-list-item>
                                    <v-list-item class="font-sm" @click="deleteVariable('storage-cost', item.StorageCost.id, item.StorageCost.office)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteStorageCost') }}</v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="4">
                <v-data-table
                    :headers="priceMarginsHeaders"
                    :items="priceMargins"
                    :items-per-page="-1"
                    :height="tableHeight"
                    :fixed-header="true"
                    class="appic-table-light specification-table"
                    dense
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top="{ items }">
                        <v-overlay
                            :value="loading.priceMargins"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <div class="d-flex flex-row pb-3">
                            <span>{{ $t('message.priceMargins') }}</span>
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openAddVariableDialog('price-margin')">
                                        <v-icon small dark v-on="on">ti ti-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.new') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:item.PriceMargin.id="{ item }">
                        <div class="text-center">
                            <v-menu>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.moreActions') }}</span>
                                    </v-tooltip>
                                </template>
                                <v-list dense>
                                    <v-list-item class="font-sm" @click="openEditVariableDialog('price-margin', item.PriceMargin.id, item.PriceMargin.title)">
                                        <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePriceMargin') }}
                                    </v-list-item>
                                    <v-list-item class="font-sm" @click="deleteVariable('price-margin', item.PriceMargin.id, item.PriceMargin.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deletePriceMargin') }}</v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <AddImportDuty
            :import-duty-id="selectedId.importDuty"
            :product-name="selectedName.importDuty"
            :dialog.sync="dialog.importDuty"
            :update-mode="updateMode.importDuty"
            @dialog-closed="dialogClosed('import-duty')"
            @update-done="reloadImportDuties()"
            @create-done="reloadImportDuties()"
        />
        <AddForwarderCost
            :forwarder-cost-id="selectedId.forwarderCost"
            :product-name="selectedName.forwarderCost"
            :dialog.sync="dialog.forwarderCost"
            :update-mode="updateMode.forwarderCost"
            @dialog-closed="dialogClosed('forwarder-cost')"
            @update-done="reloadForwarderCosts()"
            @create-done="reloadForwarderCosts()"
        />
        <AddWarehouseCost
            :storage-cost-id="selectedId.storageCost"
            :warehouse-name="selectedName.storageCost"
            :dialog.sync="dialog.storageCost"
            :update-mode="updateMode.storageCost"
            @dialog-closed="dialogClosed('storage-cost')"
            @update-done="reloadStorageCosts()"
            @create-done="reloadStorageCosts()"
        />
        <AddPriceMargin
            :price-margin-id="selectedId.priceMargin"
            :margin-name="selectedName.priceMargin"
            :update-mode="updateMode.priceMargin"
            :dialog.sync="dialog.priceMargin"
            @dialog-closed="dialogClosed('price-margin')"
            @update-done="reloadPriceMargins()"
            @create-done="reloadPriceMargins()"
        />
    </div>
</template>

<script>
import {api} from "Api";
const AddImportDuty = () => import("./AddImportDuty.vue");
const AddForwarderCost = () => import("./AddForwarderCost.vue");
const AddWarehouseCost = () => import("./AddWarehouseCost.vue");
const AddPriceMargin = () => import("./AddPriceMargin.vue");

export default {
    name: "WarehousePricingVariables",
    components: {AddPriceMargin, AddWarehouseCost, AddForwarderCost, AddImportDuty},
    data() {
        return {
            dialog: {
                forwarderCost: false,
                importDuty: false,
                priceMargin: false,
                storageCost: false
            },
            importDuties: [],
            forwarderCosts: [],
            loading: {
                forwarderCosts: false,
                importDuties: false,
                priceMargins: false,
                storageCosts: false
            },
            priceMargins: [],
            selectedId: {
                forwarderCost: null,
                importDuty: null,
                priceMargin: null,
                storageCost: null
            },
            selectedName: {
                forwarderCost: null,
                importDuty: null,
                priceMargin: null,
                storageCost: null
            },
            storageCosts: [],
            tableHeight: 250,
            updateMode: {
                forwarderCost: false,
                importDuty: false,
                priceMargin: false,
                storageCost: false
            }
        }
    },
    computed: {
        importDutiesHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('message.action'),
                    value: 'ImportDuty.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.warehouse'),
                    value: 'ImportDuty.office_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'ImportDuty.productgroup_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.type'),
                    value: 'ImportDuty.type',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.unit'),
                    value: 'ImportDuty.unit',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.duty') + ' %',
                    value: 'ImportDuty.duty',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        },
        forwarderCostsHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('message.action'),
                    value: 'ForwarderCost.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.warehouse'),
                    value: 'ForwarderCost.office_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'ForwarderCost.productgroup_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.type'),
                    value: 'ForwarderCost.type',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.unit'),
                    value: 'ForwarderCost.unit',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.cost') + ' $',
                    value: 'ForwarderCost.cost',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        },
        priceMarginsHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('message.action'),
                    value: 'PriceMargin.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.title'),
                    value: 'PriceMargin.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.margin') + ' %',
                    value: 'PriceMargin.margin',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        },
        storageCostsHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('message.action'),
                    value: 'StorageCost.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.warehouse'),
                    value: 'StorageCost.office_id',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.cost') + ' %',
                    value: 'StorageCost.cost',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        async deleteVariable(variable, variableId, variableName) {
            let variableType = ''
            let errorType = ''
            let successType = ''
            switch(variable){
                case 'import-duty':
                    variableType = 'importDuty'
                    errorType = 'importDutyNotDeleted'
                    successType = 'importDutyDeleted'
                    break;
                case 'forwarder-cost':
                    variableType = 'forwarderCost'
                    errorType = 'forwarderCostNotDeleted'
                    successType = 'forwarderCostDeleted'
                    break;
                case 'price-margin':
                    variableType = 'priceMargin'
                    errorType = 'priceMarginNotDeleted'
                    successType = 'priceMarginDeleted'
                    break;
                case 'storage-cost':
                    variableType = 'warehouseCost'
                    errorType = 'storageCostNotDeleted'
                    successType = 'warehouseCostDeleted'
                    break;
            }
            if(await this.$root.$confirm(this.$t('message.' + variableType) + ': ' +  variableName, this.$t('message.confirmations.continueVariableCancelAction'), {color: 'orange'})){
                this.deleteVariableById(variable, variableId)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.' + successType),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        }
                        switch (variable){
                            case 'import-duty':
                                this.reloadImportDuties()
                                break;
                            case 'forwarder-costs':
                                this.reloadForwarderCosts()
                                break;
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.' + errorType),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        deleteVariableById(variable, variableId) {
            return new Promise((resolve, reject) => {
                api
                    .delete('/warehouse-vars/' + variable + '/' + variableId)
                    .then(response => {
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        dialogClosed(variable) {
            switch(variable){
                case 'import-duty':
                    this.dialog.importDuty = false
                    this.updateMode.importDuty = false
                    this.selectedId.importDuty = null
                    this.selectedName.importDuty = null
                    break;
                case 'forwarder-cost':
                    this.dialog.forwarderCost = false
                    this.updateMode.forwarderCost = false
                    this.selectedId.forwarderCost = null
                    this.selectedName.forwarderCost = null
                    break;
                case 'price-margin':
                    this.dialog.priceMargin = false
                    this.updateMode.priceMargin = false
                    this.selectedId.priceMargin = null
                    this.selectedName.priceMargin = null
                    break;
                case 'storage-cost':
                    this.dialog.storageCost = false
                    this.updateMode.storageCost = false
                    this.selectedId.storageCost = null
                    this.selectedName.storageCost = null
                    break;
            }
            // if(this.updateMode == false){
            //     this.$emit('add-dialog-closed')
            // }
        },
        loadImportDuties() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/import-duty')
                    .then(response => {
                        const data = response.data.data
                        resolve(data)
                    })
                    .catch(e => reject(e))
            })
        },
        loadPriceMargins() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/price-margin')
                    .then(response => {
                        const data = response.data.data
                        resolve(data)
                    })
                    .catch(e => reject(e))
            })
        },
        loadStorageCosts() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/storage-cost')
                    .then(response => {
                        const data = response.data.data
                        resolve(data)
                    })
                    .catch(e => reject(e))
            })
        },
        loadForwarderCosts() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/forwarder-cost')
                    .then(response => {
                        const data = response.data.data
                        resolve(data)
                    })
                    .catch(e => reject(e))
            })
        },
        openAddVariableDialog(variable) {
            switch (variable){
                case 'import-duty':
                    this.dialog.importDuty = true
                    break;
                case 'forwarder-cost':
                    this.dialog.forwarderCost = true
                    break;
                case 'price-margin':
                    this.dialog.priceMargin = true
                    break;
                case 'storage-cost':
                    this.dialog.storageCost = true
                    break;
            }
        },
        openEditVariableDialog(variable, variableId, variableName) {
            switch (variable){
                case 'import-duty':
                    this.dialog.importDuty = true
                    this.selectedId.importDuty = variableId
                    this.selectedName.importDuty = variableName
                    this.updateMode.importDuty = true
                    break;
                case 'forwarder-cost':
                    this.dialog.forwarderCost = true
                    this.selectedId.forwarderCost = variableId
                    this.selectedName.forwarderCost = variableName
                    this.updateMode.forwarderCost = true
                    break;
                case 'price-margin':
                    this.dialog.priceMargin = true
                    this.selectedId.priceMargin = variableId
                    this.selectedName.priceMargin = variableName
                    this.updateMode.priceMargin = true
                    break;
                case 'storage-cost':
                    this.dialog.storageCost = true
                    this.selectedId.storageCost = variableId
                    this.selectedName.storageCost = variableName
                    this.updateMode.storageCost = true
                    break;
            }
        },
        reloadForwarderCosts() {
            this.loading.forwarderCosts = true
            this.loadForwarderCosts()
                .then(data => {
                    this.forwarderCosts = data
                    this.loading.forwarderCosts = false
                })
                .catch(error => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.forwarderCosts = false
                })
        },
        reloadImportDuties() {
            this.loading.importDuties = true
            this.loadImportDuties()
                .then(data => {
                    this.importDuties = data
                    this.loading.importDuties = false
                })
                .catch(error => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.importDuties = false
                })
        },
        reloadPriceMargins() {
            this.loading.priceMargins = true
            this.loadPriceMargins()
                .then(data => {
                    this.priceMargins = data
                    this.loading.priceMargins = false
                })
                .catch(error => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.priceMargins = false
                })
        },
        reloadStorageCosts() {
            this.loading.storageCosts = true
            this.loadStorageCosts()
                .then(data => {
                    this.storageCosts = data
                    this.loading.storageCosts = false
                })
                .catch(error => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.storageCosts = false
                })
        },
        variableAdded(variable) {
            switch (variable){
                case 'import-duty':
                    this.loadImportDuties()
                        .then(data => {
                            this.importDuties = data
                            this.dialog.importDuty = false
                        })
                        .catch(error => {
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                    break;
                case 'forwarder-cost':
                    this.loadForwarderCosts()
                        .then(data => {
                            this.forwarderCosts = data
                            this.dialog.forwarderCost = false
                        })
                        .catch(error => {
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                    break;
                case 'price-margin':
                    this.loadPriceMargins()
                        .then(data => {
                            this.priceMargins = data
                            this.dialog.priceMargin = false
                        })
                        .catch(error => {
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                    break;
                case 'storage-cost':
                    this.loadPriceMargins()
                        .then(data => {
                            this.storageCosts = data
                            this.dialog.storageCost = false
                        })
                        .catch(error => {
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                    break;
            }
        }
    },
    created() {
        this.loading.importDuties = true
        this.loadImportDuties()
            .then(data => {
                this.importDuties = data
                this.loading.importDuties = false
                this.loading.forwarderCosts = true
                this.loadForwarderCosts()
                    .then(data => {
                        this.forwarderCosts = data
                        this.loading.forwarderCosts = false
                        this.loadStorageCosts()
                            .then(data => {
                                this.storageCosts = data
                                this.loadPriceMargins()
                                    .then(data => {
                                        this.priceMargins = data
                                    })
                                    .catch(error => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch(error => {
                                this.$toast.error(error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    })
                    .catch(error => {
                        this.loading.forwarderCosts = false
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            })
            .catch(error => {
                this.loading.importDuties = false
                this.$toast.error(error,
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            })
    }
}
</script>

<style scoped>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>